import { Log } from './lib/rollbar'
import { whenDomReady } from '@common'
import './lib/polyfill'
import './scss/home.scss'
import { setTimeZoneCookies } from './lib/timezone'


const HANDLERS = {
    login: () => import('./panels/login'),
    signup: () => import('./panels/signup'),
    'app-header': () => import('./panels/app-header'),
    'screenshots': () => import('./panels/screenshots'),
    'reset-password': () => import('./panels/reset-password'),
    'homepage-header': () => import('./panels/homepage-header'),
    'find-account': () => import('./panels/find-account'),
    'request-reset': () => import('./panels/request-reset'),
    'complete-signup': () => import('./panels/complete-signup'),
}

const initialRender = true

const renderPanel = (panel: HTMLElement, args?: any) => {
    const getHandler = HANDLERS[panel.dataset?.panelId || '-1']
    if (getHandler) {
        getHandler().then((handler: any) => {
            if (handler && handler.default) {
                handler.default(
                    {
                        ...panel.dataset,
                        panel,
                        initialRender,
                    },
                    args
                )
                getHandler.rendered = true
                panel.dataset.isAttached = 'true'
                panel.classList.add('react-rendered-panel')
            } else {
                Log.critical(`handler ${panel.dataset.panelId} lacks default`)
            }
        })
    } else {
        Log.critical(`Found panel ${panel.dataset.panelId} but no handler was registered`)
    }
}

const renderPanels = async () => {
    document.querySelectorAll('[data-panel-id]').forEach(renderPanel)
}

whenDomReady(() => {
    // eslint-disable-next-line
    ;(window as any).RenderReactPanel = renderPanel
    setTimeZoneCookies()
    renderPanels()
})
