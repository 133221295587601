function setCookie(name: string, value: number) {
    document.cookie = name + '=' + value + ';max-age=' + 31536000 // one year
}

export function setTimeZoneCookies() {
    const now = new Date()

    // Set cookie for the time zone offset in minutes
    setCookie('time_zone_offset', now.getTimezoneOffset())
    // Create two new dates
    const d1 = new Date()
    const d2 = new Date()
    // Date one is set to January 1st of this year
    // Guaranteed to not be in DST
    d1.setDate(1)
    d1.setMonth(1)
    // Date two is set to July 1st of this year
    // Guaranteed to be in DST if DST exists for this time zone
    d2.setDate(1)
    d2.setMonth(7)
    // If time zone offsets match, no DST exists for this time zone
    if (d1.getTimezoneOffset() === d2.getTimezoneOffset()) {
        setCookie('time_zone_dst', 0)
    }
    // DST exists for this time zone - check if it is currently active
    else {
        // Current date is still before or after DST, not containing DST
        if (d1.getTimezoneOffset() === now.getTimezoneOffset()) {
            setCookie('time_zone_dst', 0)
        }
        // DST is active right now with the current date
        else {
            setCookie('time_zone_dst', 1)
        }
    }
}
